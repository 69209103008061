import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseVideo, Video } from '../types'
import { FileField, InputField, TextareaField, transformErrorForForm } from './form'

function VideoPreview({ url }: { url: string }) {
  return (
    <video width="400" controls>
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

const initialValues: BaseVideo = {
  title: '',
  description: '',
  file_url_vimeo: '',
  order: 0,
  url_content: '',
  video_source: '',
  school: '',
  allegato: null,
}

const UserSchema = Yup.object().shape({
  title: Yup.string().required().max(80).label('Titolo'),
  video_source: Yup.string().label('Video').required(),
  school: Yup.string().label('Scuola').required(),
  description: Yup.string().max(184).label('Description'),
  order: Yup.string().required().label('Ordine'),
})

interface Props {
  video?: Video
  save: (video: Video) => Promise<unknown>
}

export default function VideoForm({ save, video }: Props) {
  return (
    <Formik
      validationSchema={UserSchema}
      onSubmit={(savedVideo, { setErrors }) =>
        save({
          ...savedVideo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(video ?? initialValues) as Video}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                label="Titolo"
                required
                name="title"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Scuola"
                name="school"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Description"
                name="description"
                rows={5}
                component={TextareaField}
              />
            </div>
            <div className="col-md-4">
              <Field
                required
                label={'Video'}
                placeholder="Vimeo url or id"
                name={`video_source`}
                component={InputField}
              />
              {values.file_url_vimeo && (
                <VideoPreview url={values.file_url_vimeo} />
              )}
            </div>
            <div className="col-md-4">
              <Field
                label="Ordine"
                required
                name="order"
                component={InputField}
              />
            </div>
            <div className="col-md-4">
              <Field
                label="Allegato"
                required
                name="allegato"
                component={FileField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
