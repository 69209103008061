import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Annotation } from '../types'
import { makeFormDataEncoder } from './utils'

export function useAnnotations(params: Record<string, any> = {}) {
  return useQuery<Annotation[]>(
    ['annotations', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/annotations/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteAnnotation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/annotations/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['annotations'])
        client.invalidateQueries(['annotation'])
      },
    }
  )
}

const makeAnnotationFormData = makeFormDataEncoder({
  fileFields: ['image_content', 'audio_content'],
  excludeFields: [],
})

export function useCreateAnnotation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (annotation: Annotation) =>
        axios
          .post(`/api/admin/annotations/`, makeAnnotationFormData(annotation), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Annotation)
    ),
    {
      onSuccess(savedAnnotation) {
        client.invalidateQueries(['annotations'])
        client.setQueryData(['annotation', savedAnnotation.id], savedAnnotation)
      },
    }
  )
}

export function useUpdateAnnotation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (annotation: Annotation) =>
        axios
          .put(
            `/api/admin/annotations/${annotation.id}/`,
            makeAnnotationFormData(annotation),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Annotation)
    ),
    {
      onSuccess(savedAnnotation) {
        client.invalidateQueries(['annotations'])
        client.setQueryData(['annotation', savedAnnotation.id], savedAnnotation)
      },
    }
  )
}
